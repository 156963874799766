import React, { useEffect, useRef, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "../../api/fetchWithAuth"
import {
  Content, Row, SimpleTable, Col, Box, Inputs, Button
} from 'adminlte-2-react';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './UnitForm.css';

const {
  Text, Select2, Checkbox
} = Inputs;

const TEMPARATURE = 'TEMPARATURE';
const PBR = 'PBR';

const dataProvisionType = [
  {
    text: "Температура",
    value: TEMPARATURE
  },
  {
    text: "Выроботка ПБР",
    value: PBR
  },
  {
    text: "Прочие",
    value: "NOT_IMPLEMENTED"
  },
];

const columns = [
  { title: 'Протокол', data: 'protocolName' },
  { title: 'Время прихода сигнала', data: 'createdAt' },
  { title: 'Блок', data: 'unitsName' },
  { title: 'Имя', data: 'name' },
  { title: 'Тип сигнала', data: 'signalType' },
  { title: 'Код качества', data: 'quality' },
];

const UnitForm = (props) => {
  const wrapperRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const unitId = props.match.params.id;
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ onlyTeleMeasurement: false, protocolType: "" });
  const [dataTable, setDataTable] = useState([]);
  const [redirectId, setRedirectId] = useState("");
  const [dataCandidateTable, setDataCandidateTable] = useState([]);

  const [idModbus, setIdModbus] = useState();
  const [name, setName] = useState();
  const [protocolTypes, setProtocolTypes] = useState([]);

  const [hasTemperature, setHasTemperature] = useState(false);
  const [hasPbr, setHasPbr] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    checkIfDataProvisionExists();
  }, [dataCandidateTable]);


  const candidateColumns = [
    { title: '', data: 'protocolName' },
    { title: '', data: 'createdAt' },
    {
      title: '', data: 'dataProvision', render: dataProvision => <><Select2
        placeholder="Представление данных"
        allowClear
        size="sm"
        sm={10}
        disabled={dataProvision.type !== "measurement"}
        labelSm={0}
        name="dataProvision"
        options={dataProvisionType}
        value={dataProvision.value}
        onChange={(e) => handleDataProvisionChange(dataProvision.key, e.target.value)}
      /> </>,
    },
    { title: '', data: 'name' },
    { title: '', data: 'signalType' },
    { title: '', data: 'quality' },
  ];


  const handleDataProvisionChange = (key, newDataProvision) => {
    setDataCandidateTable((prevRows) =>
      prevRows.map((row) => {
        if (row.dataProvision.value === newDataProvision && row.key !== key) {
          row.dataProvision.value = "NOT_IMPLEMENTED";
        } else if (row.key === key) {
          row.dataProvision.value = newDataProvision;
        }
        return row;
      })
    );
  };

  const checkIfDataProvisionExists = () => {
    const temparatureExists = dataCandidateTable.some(row => row.type === 'measurement' && row.dataProvision.value === TEMPARATURE);
    const pbrExists = dataCandidateTable.some(row => row.type === 'measurement' && row.dataProvision.value === PBR);

    setHasTemperature(temparatureExists);
    setHasPbr(pbrExists);

    const newErrors = {};
    if (!temparatureExists) {
      newErrors.temperature = "Представление данных температура является обязательной для телеизмерений";
    }
    if (!pbrExists) {
      newErrors.PBR = "Представление данных выроботка ПБР является обязательной для телеизмерений";
    }
    setErrors(newErrors);
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChangeIdModbus = (event) => {
    const { name, value } = event.target;
    setIdModbus(value);
  };

  const handleChangeName = (event) => {
    const { name, value } = event.target;
    setName(value);
  };

  const fetchProtocolTypes = async () => {
    try {
      const response = await fetchWithAuth(`${API}/protocol-types`);
      const data = await response.json();
      const protocolTypes = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setProtocolTypes(protocolTypes);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTele = async () => {
    const teleSignals = await fetchTeleSignals();
    const teleMeasurements = await fetchTeleMeasurements();

    const table = [...teleSignals ?? [], ...teleMeasurements ?? []];
    table.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

    if (table.length > 0) {
      setDataTable(table);
    }
    else {
      setDataTable([{ protocolName: 'данные отсутствуют' }]);
    }
  }

  const fetchTeleMeasurements = async () => {
    try {
      const response = await fetchWithAuth(`${API}/tele-measurements?populate[0]=units,signalType,protocol&populate[1]=protocol.protocolType`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {

        const ids = data.data.map(item => item.id).join(",");
        const qualityMap = await getQuality(ids);

        const teleMeasurementsData = data.data.map(item => ({
          id: item.id,
          key: `${item.id}_measurement`,
          name: item.attributes.name,
          createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          unitsName: item.attributes.units.data.map(unit => `Блок_${unit.attributes.name}`).join(),
          signalType: item.attributes?.signalType?.data?.attributes?.title,
          protocolName: item.attributes?.protocol?.data?.attributes?.title,
          type: 'measurement',
          protocolTypeId: item.attributes?.protocol?.data?.attributes?.protocolType?.data?.id,
          quality: qualityMap.get(item.id) ?? null,
          dataProvision: { key: `${item.id}_measurement`, type: 'measurement' },
        }));
        return teleMeasurementsData;
      }

    } catch (error) {
      console.error(error);
    }
  };

  const getQuality = async (ids) => {
    const qualityResponse = await fetchWithAuth(`${API}/tele-quality?ids=${ids}`);
    const qualityData = await qualityResponse.json();
    const qualityMap = new Map();
    qualityData.map((obj) => {
      qualityMap.set(obj.param_id, obj.quality);
    });
    return qualityMap;
  }

  const fetchTeleSignals = async () => {
    try {
      const response = await fetchWithAuth(`${API}/tele-signals?populate[0]=units,signalType,protocol&populate[1]=protocol.protocolType`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {

        const ids = data.data.map(item => item.id).join(",");
        const qualityMap = await getQuality(ids);

        const teleSignalsData = data.data.map(item => ({
          id: item.id,
          key: `${item.id}_signal`,
          name: item.attributes.name,
          createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          unitsName: item.attributes.units.data.map(unit => `Блок_${unit.attributes.name}`).join(),
          signalType: item.attributes?.signalType?.data?.attributes?.title,
          protocolName: item.attributes?.protocol?.data?.attributes?.title,
          id: item.id,
          type: 'signal',
          protocolTypeId: item.attributes?.protocol?.data?.attributes?.protocolType?.data?.id,
          quality: qualityMap.get(item.id) ?? null,
          dataProvision: { key: `${item.id}_signal`, type: 'signal' },
        }));
        return teleSignalsData;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUnit = async () => {
    try {
      const response = await fetchWithAuth(`${API}/units/${unitId}?populate=teleSignals,teleMeasurements,temperatureTm,pbrTm`);
      const data = await response.json();
      const teleMeasurementKeys = data.data.attributes?.teleMeasurements?.data.map(item => `${item.id}_measurement`) ?? [];
      const teleSignalKeys = data.data.attributes?.teleSignals?.data.map(item => `${item.id}_signal`) ?? [];

      setIdModbus(data.data.attributes.ID_Modbus);
      setName(data.data.attributes.name);
      const teleMeasurements = dataTable.filter(({ key }) => teleMeasurementKeys.includes(key));
      const teleSignals = dataTable.filter(({ key }) => teleSignalKeys.includes(key));
      if (data.data.attributes.temperatureTm?.data?.id && teleMeasurements.length > 0) {
        teleMeasurements.find(({ id }) => id === data.data.attributes.temperatureTm.data.id).dataProvision.value = TEMPARATURE;
      }

      if (data.data.attributes.pbrTm?.data?.id && teleMeasurements.length > 0) {
        teleMeasurements.find(({ id }) => id === data.data.attributes.pbrTm.data.id).dataProvision.value = PBR;
      }

      setDataCandidateTable([...teleMeasurements, ...teleSignals]);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (unitId) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    fetchTele();
    fetchProtocolTypes();
  }, [unitId]);


  useEffect(() => {
    if (unitId) {
      fetchUnit();
    }
  }, [dataTable]);


  const handleRemove = (teleKey) => {
    setDataCandidateTable(prev => prev.filter(({ key }) => key !== teleKey));
  };

  const handleAdd = (teleKey) => {
    const item = dataTable.find(({ key }) => key === teleKey);
    item.dataProvision.value = null;
    setDataCandidateTable(prev => [...prev, item]);
  };

  const handleSave = async () => {
    try {
      const body = {
        data: {
          name: name,
          ID_Modbus: idModbus,
          teleMeasurements: dataCandidateTable.filter(({ type }) => type === 'measurement').map(item => item.id) ?? [],
          teleSignals: dataCandidateTable.filter(({ type }) => type === 'signal').map(item => item.id) ?? [],
          temperatureTm: dataCandidateTable.find(({ dataProvision }) => dataProvision.value === TEMPARATURE).id,
          pbrTm: dataCandidateTable.find(({ dataProvision }) => dataProvision.value === PBR).id
        }
      }
      if (isEditing) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };

        const response = await fetchWithAuth(`${API}/units/${unitId}`, requestOptions);
        setRedirectId(unitId);
        if (response.ok) {
          await props.updateMenu();
          await delay(500);
          redirect();
        }
      }
      else {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };

        const response = await fetchWithAuth(`${API}/units`, requestOptions);

        if (response.ok) {
          const result = await response.json();
          setRedirectId(result.data.id);
          await props.updateMenu();
          await delay(500);
          redirect();
        }
      }
    } catch (error) {
      console.error(error);
    }

  }

  const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));

  const redirect = () => {
    if (wrapperRef.current) {
      wrapperRef.current.querySelector('a').click();
    }
  };


  const Step2 = () => {
    const handleSetOnlyTeleMeasurement = ({ target: { checked } }) => {
      setFormData((prevFormData) => ({ ...prevFormData, onlyTeleMeasurement: checked }));
    };

    const handleSetOnlyTeleSignal = ({ target: { checked } }) => {
      setFormData((prevFormData) => ({ ...prevFormData, onlyTeleSignal: checked }));
    };

    const handleSetIsEmptyUnit = ({ target: { checked } }) => {
      setFormData((prevFormData) => ({ ...prevFormData, isEmptyUnit: checked }));
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    let localData = dataTable;

    if (formData.onlyTeleMeasurement) {
      localData = localData.filter(({ type }) => type === 'measurement');
    }

    if (formData.onlyTeleSignal) {
      localData = localData.filter(({ type }) => type === 'signal');
    }

    if (formData.isEmptyUnit) {
      localData = localData.filter(({ unitsName }) => unitsName === '');
    }

    if (formData.protocolType) {
      localData = localData.filter(({ protocolTypeId }) => protocolTypeId === parseInt(formData.protocolType));
    }

    if (dataCandidateTable && dataCandidateTable.length > 0) {
      localData = localData.filter(({ key }) => dataCandidateTable.findIndex(item => item.key === key) === -1)
    }

    return (
      <>
        <Row><Col md={4}> </Col><Col style={{ marginLeft: 20 }} md={3}> <h4> Добавление телеизмерений и телесигналов </h4> </Col> <Col md={5}></Col> </Row>
        <Row><Col md={5}> </Col><Col style={{ marginLeft: 20 }} md={2}> Добавлены к блоку </Col> <Col md={5}></Col> </Row>
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <SimpleTable
              hover
              condensed
              columns={candidateColumns.concat([{
                title: '',
                data: 'key',
                render: key => key && <><Button icon="fa-trash" onClick={() => handleRemove(key)} className="clickable" /> </>,
              }])}
              data={dataCandidateTable} />
          </Col>
          <Col md={3}> </Col>
        </Row>
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            {dataCandidateTable.length > 0 && Object.keys(errors).length > 0 && (
              <div className="warning-summary">
                {errors.temperature && <p><FontAwesomeIcon color={"#fab005"}
                  icon={["fas", "exclamation-triangle"]}
                />{errors.temperature}</p>}
                {errors.PBR && <p> <FontAwesomeIcon color={"#fab005"}
                  icon={["fas", "exclamation-triangle"]} /> {errors.PBR}</p>}
              </div>
            )}
          </Col>
          <Col md={3}> </Col>
        </Row>
        <Row>
          <Col md={3}> </Col>
          <Col sm={6}>
            <Box type="default" solid>
              <Row>
                <Col md={2}>
                  <div class="checkbox">
                    <label>
                      <input checked={formData.onlyTeleSignal} onChange={handleSetOnlyTeleSignal} type="checkbox" />  телесигнал
                    </label>
                  </div>
                </Col>
                <Col md={3}>
                  <div class="checkbox">
                    <label>
                      <input checked={formData.onlyTeleMeasurement} onChange={handleSetOnlyTeleMeasurement} type="checkbox" />  телеизмерение
                    </label>
                  </div>
                </Col>

                <Col md={3}>
                  <div class="checkbox">
                    <label>
                      <input checked={formData.isEmptyUnit} onChange={handleSetIsEmptyUnit} type="checkbox" /> Блок не задан
                    </label>
                  </div>
                </Col>

                <Col md={4}>
                  <Select2
                    placeholder="Стандарт протокола"
                    allowClear
                    size="sm"
                    sm={12}
                    labelSm={0}
                    name="protocolType"
                    options={protocolTypes}
                    value={formData.protocolType}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Box>
          </Col>
          <Col md={3}> </Col>
        </Row>
        <Row><Col md={5}> </Col><Col style={{ marginLeft: 20 }} md={2}> Доступные ТИ и ТС </Col> <Col md={5}></Col> </Row>
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <SimpleTable
              hover
              condensed
              columns={columns.concat([{
                title: '',
                data: 'key',
                render: key => key && <><Button icon="fa-plus" onClick={() => handleAdd(key)} className="clickable" /> </>,
              }])}
              data={localData} />
          </Col>
          <Col md={3}> </Col>
        </Row></>
    );
  };

  return (
    <Content title={isEditing ? "Редактирование блока" : "Добавление блока"}>
      <Box type="primary" title="">
        <div>
          {currentStep === 1 && (
            <><Row>
              <Col md={4}>
              </Col>
              <Col md={3}>
                <Text labelSm={4} sm={3} onChange={handleChangeIdModbus} name="idModbus" value={idModbus} inputType="number" placeholder="" label="ID Modes Terminal" labelPosition="above" />
              </Col>
              <Col md={5}>
              </Col>
            </Row><Row>
                <Col md={4}>
                </Col>
                <Col md={3}>
                  <Text labelSm={4} sm={3} onChange={handleChangeName} name="name" value={name} placeholder="" label="Название блока" labelPosition="above" />
                </Col>
                <Col md={5}>
                </Col>
              </Row>
            </>
          )}
          {currentStep === 2 && (
            <Step2 formData={formData} />
          )}

          <div style={{ marginTop: "20px" }}>
            {currentStep < 2 && <Row><Col md={5}></Col><Col style={{ marginLeft: 20 }} md={2}> <Button size="md" onClick={handleNext} type="primary" text="Далее" /></Col> <Col md={5}></Col> </Row>}

            {currentStep === 2 && (
              <Row>
                <Col md={3}> </Col>
                <Col md={6}>
                  <Button size="md" onClick={handleBack} type="primary" text="Назад" pullLeft />
                  <Button size="md" disabled={Object.keys(errors).length > 0 || dataCandidateTable.length === 0} onClick={handleSave} type="primary" text="Сохранить" pullRight />
                </Col>
                <Col md={3}> </Col>
              </Row>
            )}
          </div>
          {/* Проблема с либой adminlte-2-react она используется react router v5 */}
          <div style={{ display: "none" }} ref={wrapperRef}> <Button to={redirectId && `/main/${redirectId}/`}></Button> </div>
        </div>
      </Box>
    </Content>
  );
}

export default UnitForm;
