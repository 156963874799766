import React, { useRef, useEffect, useState } from 'react';
import {
  Row, Col, Inputs, Button,
} from 'adminlte-2-react';
import moment from 'moment';

import ReactDateTime from 'react-datetime';
import 'moment/locale/ru';
import { API } from "../../constant";
import ModalDialog from './ModalDialog'
import { fetchWithAuth } from "./../../api/fetchWithAuth"
import { useAuthContext } from "./../../context/AuthContext";

moment.locale('ru')

const {
  Text, Select
} = Inputs;


function UdgForm({ udgUnitTitle, showConfirmDialog, onHideConfirmDialog, showResult, unitId }) {
  const inputRef = useRef(null);
  const { user } = useAuthContext();
  const [formData, setFormData] = useState({
    deviationReason: "1",
    initiativeType: "1",
    category: "1",
    formula: "1",
    commentTemplate: "1",
    value: 0,
    acceptedBy: user.id,
    receivedAt: moment().format("YYYY-MM-DD HH:mm"),
    startAt: moment().add(10, 'm').format("YYYY-MM-DD HH:mm"),
    endAt: moment().add(40, 'm').format("YYYY-MM-DD HH:mm")
  });

  const [deviationReasons, setDeviationReasons] = useState([]);
  const [initiativeType, setInitiativeType] = useState([]);
  const [categories, setCategories] = useState([]);
  const [commentTemplates, setCommentTemplate] = useState([]);
  const [formulas, setFormulas] = useState([]);
  const [result, setResult] = useState(null);

  const fetchDeviationReasons = async () => {
    try {
      const response = await fetchWithAuth(`${API}/deviation-reasons`);
      const data = await response.json();
      const reasons = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setDeviationReasons(reasons);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetchWithAuth(`${API}/udg-categories`);
      const data = await response.json();
      const categories = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setCategories(categories);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFormulas = async () => {
    try {
      const response = await fetchWithAuth(`${API}/Udg-formulas`);
      const data = await response.json();
      const formulas = (data?.data ?? []).map(item => ({ text: item.attributes.title, id: item.id, value: item.id, code: item.attributes.code }))
      setFormulas(formulas);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCommentTemplates = async () => {
    try {
      const response = await fetchWithAuth(`${API}/udg-comment-templates`);
      const data = await response.json();
      const commentTemplates = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setCommentTemplate(commentTemplates);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInitiativeTypes = async () => {
    try {
      const response = await fetchWithAuth(`${API}/initiative-types`);
      const data = await response.json();
      const initiativeTypes = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setInitiativeType(initiativeTypes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDeviationReasons();
    fetchInitiativeTypes();
    fetchCategories();
    fetchFormulas();
    fetchCommentTemplates();
    if(inputRef.current) {
      inputRef.current.ref.min = 0;
    }
    
  }, []);
  const getText = () => {
    return `${udgUnitTitle} c ${formData.startAt} к: ${formData.endAt}\n${formulas.find((item) => item.value == formData.formula)?.text.replace("\"N\"", formData.value) || ""}\n${initiativeType.find((item) => item.value == formData.initiativeType)?.text || ""}\n\nПричина: ${deviationReasons.find((item) => item.value == formData.deviationReason)?.text || ""}\nКомментарий: ${commentTemplates.find((item) => item.value == formData.commentTemplate)?.text.replace("\"N\"", formData.value) || ""}`
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };


  const insert = async () => {
    const body = formData;
    body.unitId = unitId;
    body.comment = commentTemplates.find((item) => item.value == formData.commentTemplate)?.text.replace("\"N\"", formData.value) || "";
    body.udgLogText = getText();

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    };

    const response = await fetchWithAuth(`${API}/udg-form`, requestOptions);
    const data = await response.json();
    setResult(data.data);
    onHideConfirmDialog();
    showResult();
  }

  const valid = (current) => {
    const yesterday = moment().subtract( 1, 'day' ).format("YYYY-MM-DD HH:mm");
    return current.isAfter( yesterday );
  }

  return (
    <div>
      {result ?
        <div className="form-horizontal">
          <Row style={{ height: '100px' }}>
            <Col md={12}>
              <div className="box-body">
                {result}
              </div>
            </Col>
          </Row>
        </div>
        :
        <div className="form-horizontal">
          <ModalDialog
            showPrimaryBox={false}
            modalSize="md"
            title="Подтверждение команды"
            show={showConfirmDialog}
            onHide={onHideConfirmDialog}
            modalFooter=
            {
              <div className="box-footer">
                <Button text="Отменить" onClick={onHideConfirmDialog} pullLeft />
                <Button text="Подтвердить отправку" onClick={insert} type="info" pullRight />
              </div>
            }
          >
            <div style={{ whiteSpace: 'pre-wrap' }}> {getText()} </div>
          </ModalDialog>
          <Col md={6}>
            <div className="box-body">
              <Select
                size="sm"
                label="Категория команды"
                labelPosition="above"
                name="category"
                options={categories}
                value={formData.category}
                onChange={handleChange}
              />
              <Select
                label="Формулировка команды"
                labelPosition="above"
                name="formula"
                options={formulas.map(item => ({ value: item.value, text: item.text }))}
                value={formData.formula}
                onChange={handleChange}
                multiple
              />
              <Select
                size="sm"
                label="Инициатива"
                labelPosition="above"
                name="initiativeType"
                options={initiativeType}
                value={formData.initiativeType}
                onChange={handleChange}
              />
              <Select
                size="sm"
                label="Причина отклонения"
                labelPosition="above"
                name="deviationReason"
                options={deviationReasons}
                value={formData.deviationReason}
                onChange={handleChange}

              />
              <Select
                size="sm"
                label="Комментарий"
                labelPosition="above"
                name="commentTemplate"
                options={commentTemplates}
                value={formData.commentTemplate}
                onChange={handleChange}
              />
              <div className="form-group">
                <textarea className="form-control input-sm" value={commentTemplates.find((item) => item.value == formData.commentTemplate)?.text.replace("\"N\"", formData.value) || ""} rows={2} readOnly={true} />
              </div >
              <div style={{ height: 70, display: (formulas.find((item) => item.value == formData.formula)?.code ? 'none' : 'block') }}>
              </div>
              <div style={{ display: (formulas.find((item) => item.value == formData.formula)?.code ? 'block' : 'none') }}>
                <Text size="sm" ref={inputRef} name="value" onChange={handleChange} value={formData.value} inputType="number" in label="Генерация, МВт" labelPosition="above" />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="box-body">
              <div className="form-group">
                <label>Время получения команды</label>
                <ReactDateTime
                  inputProps={{ className: "form-control input-sm" }}
                  timeFormat={"HH:mm"}
                  locale="ru"
                  dateFormat="YYYY-MM-DD"
                  value={formData.receivedAt}
                  onChange={(event) => {
                    setFormData((prevFormData) => ({ ...prevFormData, ["receivedAt"]: moment(event).format("YYYY-MM-DD HH:mm") }));
                  }}
                />
              </div>

              <div className="form-group">
                <label>Время начала исполнения</label>
                <ReactDateTime
                  inputProps={{ className: "form-control input-sm" }}
                  timeFormat={"HH:mm"}
                  isValidDate={ valid }
                  locale="ru"
                  dateFormat="YYYY-MM-DD"
                  value={formData.startAt}
                  onChange={(event) => {
                    setFormData((prevFormData) => ({ ...prevFormData, ["startAt"]: moment(event).format("YYYY-MM-DD HH:mm") }));
                  }}
                />
              </div>

              <div className="form-group">
                <label>Время окончания исполнения</label>
                <ReactDateTime
                  inputProps={{ className: "form-control input-sm" }}
                  timeFormat={"HH:mm"}
                  isValidDate={ valid }
                  locale="ru"
                  dateFormat="YYYY-MM-DD"
                  value={formData.endAt}
                  onChange={(event) => {
                    setFormData((prevFormData) => ({ ...prevFormData, ["endAt"]: moment(event).format("YYYY-MM-DD HH:mm") }));
                  }}
                />
              </div>

              <div class="form-group">
                <label>Команда получена от:</label><br />  Кубанское РДУ
              </div>
              <div class="form-group">
                <label>Персонал, отдавший команду:</label><br /> Диспетчер Кубанского РДУ
              </div>

              <div class="form-group">
                <label>Персонал, принявший команду в ЛУКОЙЛ-Кубаньэнерго:</label><br /> {user.name}
              </div>
            </div>
          </Col>
          <Row>
            <Col md={12}>
              <div className="box-body">
                <label>Запись в журнале</label>
                <textarea className="form-control input-sm" value={getText()} rows={6} readOnly={true}></textarea>
              </div>
            </Col>
          </Row>
        </div>
      }
    </div>
  );
}

export default UdgForm;


