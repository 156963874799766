import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "../../api/fetchWithAuth"
import AngleCheckbox from "../../components/Inputs/AngleCheckbox"
import { useNavigate } from "react-router-dom";
import {
  Content, Row, SimpleTable, Col, Box, Inputs, Button, Label
} from 'adminlte-2-react';

const {
  Text, Select, Checkbox
} = Inputs;



const TeleMeasurementForm = (props) => {
  const navigate = useNavigate();
  const teleMeasurementId = props.match.params.id;

  const [isEditing, setIsEditing] = useState(false);
  const [useProtocol, setUseProtocol] = useState(false);
  const [protocolTypes, setProtocolTypes] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const [signalTypes, setSignalTypes] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSetIsNewProtocol = (val) => {
    setFormData((prevFormData) => ({ ...prevFormData, isNewProtocol: val }));
  };

  const [formData, setFormData] = useState({
    protocolType: null,
    isNewProtocol: true,
    protocol: null,
  });

  useEffect(() => {
    fetchProtocolTypes();
  }, []);

  const initialData = async () => {
    await fetchProtocols();
    await fetchSignalTypes();
    await fetchTeleMeasurement();
  }
  useEffect(() => {
    if (teleMeasurementId) {
      setIsEditing(true);
      initialData();

    } else {
      setIsEditing(false);
      setFormData({ isNewProtocol: true, teleMeasurementName: '', teleMeasurementAddress: '', protocolName: '', ipAddress: '', port: '', ASDU: '', protocolType: null,signalType: null });
      fetchProtocols();
      fetchSignalTypes();
    }
  }, [teleMeasurementId]);

  const fetchTeleMeasurement = async () => {
    try {
      const response = await fetchWithAuth(`${API}/tele-measurements/${teleMeasurementId}?populate=protocol,signalType`);
      const teleMeasurement = await response.json();
      setFormData({
        teleMeasurementName: teleMeasurement.data.attributes.name,
        teleMeasurementAddress: teleMeasurement.data.attributes.address,
        protocol: teleMeasurement.data.attributes.protocol.data.id,
        signalType: teleMeasurement.data.attributes.signalType?.data?.id,
        isNewProtocol: false,
      });
      setUseProtocol(true);

    } catch (error) {
      console.error(error);
    }
  };

  const fetchProtocolTypes = async () => {
    try {
      const response = await fetchWithAuth(`${API}/protocol-types`);
      const data = await response.json();
      const protocolTypes = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setProtocolTypes(protocolTypes);
      setFormData((prevFormData) => ({ ...prevFormData, protocolType: protocolTypes[0].value }));
    } catch (error) {
      console.error(error);
    }
  };


  const fetchProtocols = async () => {
    try {
      const response = await fetchWithAuth(`${API}/protocols`);
      const data = await response.json();
      const protocols = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setProtocols(protocols);
      setFormData((prevFormData) => ({ ...prevFormData, protocol: protocols[0].value }));
      if (!isEditing && protocols?.length > 0) {
        setUseProtocol(true);
        handleSetIsNewProtocol(false)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSignalTypes = async () => {
    try {
      const response = await fetchWithAuth(`${API}/signal-types`);
      const data = await response.json();
      const signalTypes = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setSignalTypes(signalTypes);
      setFormData((prevFormData) => ({ ...prevFormData, signalType: signalTypes[0].value }));
    } catch (error) {
      console.error(error);
    }
  };

  const saveNewTeleMeasurement = async () => {
    try {

      let protocolId = formData.protocol;
      if (!useProtocol) {
        protocolId = await saveNewProtocol();
      }

      const body = {
        data: {
          protocol: protocolId,
          name: formData.teleMeasurementName,
          address: formData.teleMeasurementAddress,
          signalType: formData.signalType
        }
      };

      if (isEditing) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };

        const response = await fetchWithAuth(`${API}/tele-measurements/${teleMeasurementId}`, requestOptions);
        if (response.ok) {
          navigate(-1)
        }
      }
      else {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };

        const response = await fetchWithAuth(`${API}/tele-measurements`, requestOptions);
        if (response.ok) {
          navigate(-1)
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveNewProtocol = async () => {
    try {

      const body = {
        data: {
          title: formData.protocolName,
          ipAddress: formData.ipAddress,
          port: formData.port,
          ASDU: formData.ASDU,
          protocolType: formData.protocolType,
        }
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };

      const response = await fetchWithAuth(`${API}/protocols`, requestOptions);
      if (response.ok) {
        const protocol = await response.json();
        return protocol.data.id;
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeUseProtocol = () => {
    setUseProtocol(prevState => !prevState);
  }

  return (
    <Content title={isEditing ? "Редактирование телеизмерения" : "Добавление телеизмерения"}>
      <Box type="primary" title="">
        <Row>
          <Col md={4}>
          </Col>
          <Col md={4}>
            <AngleCheckbox checked={formData.isNewProtocol}
              onChange={handleSetIsNewProtocol} text="Создать новый протокол:"></AngleCheckbox>
            {formData.isNewProtocol &&
              <div>
                <Select
                  size="sm"
                  label="Стандарт протокола"
                  labelPosition="above"
                  name="protocolType"
                  options={protocolTypes}
                  value={formData.protocolType}
                  onChange={handleChange} />
                <Text name="protocolName" onChange={handleChange} value={formData.protocolName} label="Имя протокола для ТИ" labelPosition="above" />
                <Text name="port" inputType="number" onChange={handleChange} value={formData.port} label="Адрес порта" labelPosition="above" />
                <Text name="ipAddress" onChange={handleChange} value={formData.ipAddress} label="IP сервера" labelPosition="above" />
                <Text name="ASDU" inputType="number" onChange={handleChange} value={formData.ASDU} label="Адрес станции (МЭК-104) /  Name Space (OPC UA)" labelPosition="above" />
              </div>
            }
            {protocols.length > 0 &&
              <div>
                <div class="checkbox">
                  <label>
                    <input checked={useProtocol} onChange={handleChangeUseProtocol} type="checkbox" /> Использовать готовые параметры
                  </label>
                </div>

                <Select
                  size="sm"
                  label="Протокол"
                  labelPosition="above"
                  name="protocol"
                  options={protocols}
                  value={formData.protocol}
                  onChange={handleChange}
                />
              </div>
            }

            <Text name="teleMeasurementAddress" onChange={handleChange} inputType="number" value={formData.teleMeasurementAddress} label="Адрес телеизмерения" labelPosition="above" />
            <Text name="teleMeasurementName" onChange={handleChange} value={formData.teleMeasurementName} label="Имя телеизмерения" labelPosition="above" />
            <Select
                  size="sm"
                  label="Тип сигнала"
                  labelPosition="above"
                  name="signalType"
                  options={signalTypes}
                  value={formData.signalType}
                  onChange={handleChange} />
            <Button size="md" text="Назад" onClick={() => navigate(-1)} pullLeft />
            <Button size="md" type="primary" onClick={saveNewTeleMeasurement} text="Сохранить телеизмерение" pullRight />

          </Col>
          <Col md={4}>
          </Col>
        </Row>
      </Box>
    </Content>
  );
}

export default TeleMeasurementForm;
