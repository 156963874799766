import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "../../api/fetchWithAuth"
import { useNavigate } from "react-router-dom";

import {
    Content, Row, Col, Box, Inputs, Button
} from 'adminlte-2-react';

const {
    Text, Select
} = Inputs;


const ProtocolForm = (props) => {
    const navigate = useNavigate();
    const protocolId = props.match.params.id;

    const [protocolTypes, setProtocolTypes] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };


    const [formData, setFormData] = useState({
        protocolType: null,
    });

    const initialData = async () => {
        await fetchProtocolTypes();
        await fetchProtocol();
    }

    useEffect(() => {
        if (protocolId) {
            setIsEditing(true);
            initialData();

        } else {
            setIsEditing(false);
            setFormData({ title: '', ipAddress: '', secondaryIpAddress: '', port: '', ASDU: '', protocolType: null });
            fetchProtocolTypes()
        }
    }, [protocolId]);

    const fetchProtocolTypes = async () => {
        try {
            const response = await fetchWithAuth(`${API}/protocol-types`);
            const data = await response.json();
            const protocolTypes = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
            setProtocolTypes(protocolTypes);
            setFormData({ protocolType: protocolTypes[0].value });
        } catch (error) {
            console.error(error);
        }
    };

    const fetchProtocol = async () => {
        try {
            const response = await fetchWithAuth(`${API}/protocols/${protocolId}?populate=protocolType`);
            const protocol = await response.json();
            setFormData({
                title: protocol.data.attributes.title,
                ipAddress: protocol.data.attributes.ipAddress,
                secondaryIpAddress: protocol.data.attributes.secondaryIpAddress,
                port: protocol.data.attributes.port,
                ASDU: protocol.data.attributes.ASDU,
                protocolType: protocol.data.attributes.protocolType.data.id,
            });

        } catch (error) {
            console.error(error);
        }
    };

    const saveNewProtocol = async () => {
        try {

            const body = {
                data: formData
            };

            if (isEditing) {
                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body)
                };

                const response = await fetchWithAuth(`${API}/protocols/${protocolId}`, requestOptions);
                if (response.ok) {
                    navigate(-1)
                }
            }
            else {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body)
                };

                const response = await fetchWithAuth(`${API}/protocols`, requestOptions);
                if (response.ok) {
                    navigate(-1)
                }
            }


        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Content title={isEditing ? "Редактирование протокола" : "Добавление протокола"}>

            <Box type="primary" title="">
                <Row>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <Text name="title" onChange={handleChange} value={formData.title} label="Имя протокола для ТИ" labelPosition="above" />
                        <Select
                            size="sm"
                            label="Протокол"
                            labelPosition="above"
                            name="protocolType"
                            options={protocolTypes}
                            value={formData.protocolType}
                            onChange={handleChange}
                        />
                        <Text name="ipAddress" onChange={handleChange} value={formData.ipAddress} label="IP сервера" labelPosition="above" />
                        <Text name="secondaryIpAddress" onChange={handleChange} value={formData.secondaryIpAddress} label="Резервный IP сервера" labelPosition="above" />
                        <Text name="port" inputType="number" onChange={handleChange} value={formData.port} label="Адрес порта" labelPosition="above" />
                        <Text name="ASDU" inputType="number" onChange={handleChange} value={formData.ASDU} label="Адрес станции (МЭК-104) /  Name Space (OPC UA)" labelPosition="above" />

                        <Button size="md" text="Назад" onClick={() => navigate(-1)} pullLeft />
                        <Button size="md" type="primary" onClick={saveNewProtocol} text="Сохранить протокол" pullRight />
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
            </Box>
        </Content>
    );
}

export default ProtocolForm;
