import {
  Content, Row, Box, SimpleTable, Col, ProgressBar, Badge, Label, Inputs, Button,
} from 'adminlte-2-react';
import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { getToken } from "../../helpers";
import { fetchWithAuth } from "./../../api/fetchWithAuth"

const columns = [
      { title: 'дата', data: 'date' },
      { title: 'создал', data: 'userName' },
      { title: 'комментарий', data: 'description' }
];

function UdgLog({ period, unitId }) {

  const authToken = getToken();

  const [logData, setLogData] = useState([]);


  const fetchLog = async () => {
    try {
      const response = await fetchWithAuth(`${API}/udg-log?unitId=${unitId}&period=${period}`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.length > 0) {
        setLogData(data);
      }
      else {
        setLogData([{date: 'команды отсутствуют'}]);
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLog();
  }, []);

  return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <SimpleTable
                  hover
                  condensed
                  border
                  striped
                  columns={columns}
                  data={logData}
                />
      </div>
  );
}

export default UdgLog;