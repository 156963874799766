import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalDialog = ({
  title,
  modalCloseTo,
  show = true,
  modalFooter,
  children,
  onHide,
  modalSize,
  modalCloseButton = true,
  showPrimaryBox = true,
  backdrop = false,
}) => {
  const navigate = useNavigate();
  const handleHide = () => {
    if (modalCloseTo) navigate(modalCloseTo);
    if (onHide) onHide();
  };

  const handleExited = () => {
    if (modalCloseTo) navigate(modalCloseTo);
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      onExited={handleExited}
      bsSize={modalSize}
      enforceFocus={false}
      {...(backdrop !== false && { backdrop: 'static' })}
    >
      <div style={{ padding: '10px' }}>
        <div className={showPrimaryBox ? 'box box-primary' : ''}>
          <Modal.Header closeButton={modalCloseButton}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '5px' }}>
            {children}
          </Modal.Body>
          {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
        </div>
      </div>
    </Modal>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.string,
  modalCloseTo: PropTypes.string,
  show: PropTypes.bool,
  showPrimaryBox: PropTypes.bool,
  modalFooter: PropTypes.node,
  children: PropTypes.node,
  onHide: PropTypes.func,
  modalSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  modalCloseButton: PropTypes.bool,
  backdrop: PropTypes.bool,
};

ModalDialog.defaultProps = {
  title: null,
  modalCloseTo: null,
  show: true,
  modalFooter: null,
  children: null,
  onHide: null,
  modalSize: null,
  modalCloseButton: true,
  showPrimaryBox: true,
  backdrop: false,
};

export default ModalDialog;