import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "../../api/fetchWithAuth"
import {
  Content, Row, Col, Box, Inputs, Button, DataTable, SmartTable, SimpleTable
} from 'adminlte-2-react';
import ModalDialog from '../../components/Modal/ModalDialog';
import moment from 'moment';

const columns = [
  { title: 'Время прихода сигнала', data: 'createdAt' },
  { title: 'Блоки', data: 'unitsName' },
  { title: 'Имя', data: 'name' },
  { title: 'Тип сигнала', data: 'signalType' },
  { title: 'Код качества', data: 'quality' },
];


const TeleSignals = () => {
  const [teleSignals, setTeleSignals] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [candidateforDeletion, setCandidateforDeletion] = useState();

  const handleRemove = (id) => {
    setCandidateforDeletion(id)
    toggleConfirmDialog();
  };

  const getQuality = async (ids) => {
    const qualityResponse = await fetchWithAuth(`${API}/tele-quality?ids=${ids}`);
    const qualityData = await qualityResponse.json();
    const qualityMap = new Map();
    qualityData.map((obj) => {
      qualityMap.set(obj.param_id, obj.quality);
    });

    return qualityMap;
  }

  const fetchTeleSignals = async () => {
    try {
      const response = await fetchWithAuth(`${API}/tele-signals?populate=units,signalType`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {
        const ids = data.data.map(item => item.id).join(",");
        const qualityMap  = await getQuality(ids);

        const teleSignalsData = data.data.map(item => ({
          id: item.id,
          name: item.attributes.name,
          createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          unitsName:  item.attributes.units.data.map(unit => `Блок_${unit.attributes.name}`).join(),
          signalType: item.attributes?.signalType?.data?.attributes?.title,
          quality: qualityMap.get(item.id) ?? null
        }));
        setTeleSignals(teleSignalsData);
      }
      else {
        setTeleSignals([{ unitName: 'телесигналы отсутствуют' }]);
      }

    } catch (error) {
      console.error(error);
    }
  };

  const toggleConfirmDialog = () => {
    setShowConfirmDialog(prevState => !prevState);
  }

  useEffect(() => {
    fetchTeleSignals();
  }, []);

  const removeTeleSignal = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetchWithAuth(`${API}/tele-signals/${candidateforDeletion}`, requestOptions);
    if (response.ok) {
      setCandidateforDeletion(null); 
      toggleConfirmDialog();
      fetchTeleSignals();
    }
  }


  const getText = () => {
    if(!candidateforDeletion) {
      return "";
    }

    return `Вы уверены что хотите удалить телесигнал ${teleSignals.find(item => item.id === candidateforDeletion).name}`
  }

  return (
    <Content title="Управление телесигналами">
      <ModalDialog
        showPrimaryBox={false}
        modalSize="md"
        title="Подтверждение удаления телесегнала"
        show={showConfirmDialog}
        onHide={toggleConfirmDialog}
        modalFooter=
        {
          <div className="box-footer">
            <Button text="Отменить" onClick={toggleConfirmDialog} pullLeft />
            <Button text="Подтвердить удаление" onClick={removeTeleSignal} type="info" pullRight />
          </div>
        }
      >
        <div style={{ marginLeft:10, whiteSpace: 'pre-wrap' }}> {getText()} </div>
      </ModalDialog>
      <Box type="primary" title="">
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <Button className="unstyled-button" to="/telesignal/new" text="Создать телесигнал" icon="fa-plus-circle" />
            <SimpleTable
              hover
              condensed
              columns={columns.concat([{
                title: '',
                data: 'id',
                render: id => id && <><Button icon="fa-trash" onClick={() => handleRemove(id)} className="clickable" /><Button to={`/telesignal/edit/${id}`} icon="fa-cogs" className="clickable" /></>,
              }])}
              data={teleSignals}
            />
          </Col>
          <Col md={3}> </Col>
        </Row>
      </Box>
    </Content>
  );
}

export default TeleSignals;
