export function get_config_gou(gou_mode, minv, maxv) {
    return gou_mode === 'x' ? get_config_gou_x(minv, maxv) : get_config_gou_y(minv, maxv);
}

function get_config_gou_y(minv, maxv) {
    
    return {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                point:{
                    radius: 0
                }
            },
            animation: {
                duration: 700
            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Блоки ГОУ'
                },
                tooltip: {
                    filter: function (tooltipItem) {
                        //return tooltipItem.datasetIndex !== 1;
                        return false;
                    },
                    //display: false
                },
                annotation: {
                    annotations: {
                        min: {
                            type: 'line',
                            mode: 'vertical',
                            xMin: minv,
                            xMax: minv,
                            borderColor: '#8a8a8a',
                            borderWidth: 2,
                        },
                        min_label: {
                            type: 'label',
                            xValue: minv,
                            xAdjust: -15,
                            yValue: 0.43,
                            //backgroundColor: 'rgba(245,245,245)',
                            content: [minv],
                            font: {
                                size: 12
                            }
                        },
                        udg: {
                            type: 'line',
                            mode: 'vertical',
                            xMin: maxv,
                            xMax: maxv,
                            borderColor: '#00aa00',
                            borderWidth: 2,
                            label: {
                                content: maxv,
                                enabled: true,
                                position: 'bottom'
                            }
                        },
                        udg_label: {
                            type: 'label',
                            xValue: maxv,
                            xAdjust: -20,
                            yValue: 0.43,
                            //backgroundColor: 'rgba(245,245,245)',
                            content: [maxv],
                            font: {
                                size: 12
                            }
                        },
                        max: {
                            type: 'line',
                            mode: 'vertical',
                            xMin: maxv + 20,
                            xMax: maxv + 20,
                            borderColor: '#aa0000',
                            borderWidth: 2,
                            label: {
                                content: maxv + 20,
                                enabled: true,
                                position: 'bottom'
                            }
                        },
                        max_label: {
                            type: 'label',
                            xValue: maxv + 20,
                            xAdjust: 15,
                            yValue: 0.43,
                            //backgroundColor: 'rgba(245,245,245)',
                            content: [maxv + 20],
                            font: {
                                size: 12
                            }
                        },
                        real: {
                            type: 'label',
                            xValue: 30,
                            xAdjust: 0,
                            yValue: 0,
                            backgroundColor: '#dadada99',
                            content: ['Факт: 0', 'Резерв: 0'],
                            font: {
                                size: 14
                            }
                        },
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    max: maxv + 30,
                },
                y: { 
                    stacked: true 
                } 
            }
        }
    
}

function get_config_gou_x(minv, maxv) {
    
    return {
            indexAxis: 'x',
            responsive: true,
            maintainAspectRatio: true,
            elements: {
                point:{
                    radius: 0
                }
            },
            animation: {
                duration: 700
            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Блоки ГОУ'
                },
                tooltip: {
                    filter: function (tooltipItem) {
                        return tooltipItem.datasetIndex !== 1;
                    }
                },
                annotation: {
                    annotations: {
                        min: {
                            type: 'line',
                            mode: 'vertical',
                            yMin: minv,
                            yMax: minv,
                            borderColor: '#8a8a8a',
                            borderWidth: 2,
                        },
                        min_label: {
                            type: 'label',
                            yValue: minv,
                            yAdjust: -10,
                            xAdjust: 10,
                            xValue: 0.3,
                            //backgroundColor: 'rgba(245,245,245)',
                            content: [minv],
                            font: {
                                size: 12
                            }
                        },
                        udg: {
                            type: 'line',
                            mode: 'vertical',
                            yMin: maxv,
                            yMax: maxv,
                            borderColor: '#00aa00',
                            borderWidth: 2,
                            label: {
                                content: maxv,
                                enabled: true,
                                position: 'bottom'
                            }
                        },
                        udg_label: {
                            type: 'label',
                            yValue: maxv,
                            yAdjust: -10,
                            xAdjust: 10,
                            xValue: 0.3,
                            //backgroundColor: 'rgba(245,245,245)',
                            content: [maxv],
                            font: {
                                size: 12
                            }
                        },
                        max: {
                            type: 'line',
                            mode: 'vertical',
                            yMin: maxv + 20,
                            yMax: maxv + 20,
                            borderColor: '#aa0000',
                            borderWidth: 2,
                            label: {
                                content: maxv + 20,
                                enabled: true,
                                position: 'bottom'
                            }
                        },
                        max_label: {
                            type: 'label',
                            yValue: maxv + 20,
                            yAdjust: -10,
                            xAdjust: 10,
                            xValue: 0.3,
                            //backgroundColor: 'rgba(245,245,245)',
                            content: [maxv + 20],
                            font: {
                                size: 12
                            }
                        },
                        real: {
                            type: 'label',
                            yValue: 30,
                            yAdjust: 0,
                            xValue: 0,
                            backgroundColor: '#dadada99',
                            content: ['Факт: 0', 'Резерв: 0'],
                            font: {
                                size: 14
                            }
                        },
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    //max: maxv + 30,
                },
                
                y: { 
                    stacked: true,
                    max: maxv + 30,
                } 
            }
        }
}

export function get_config_udg() {
    
    return {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'interpolate',
                intersect: false,
                axis: 'x',
            },
            animation: {
                duration: 0
            },
            elements: {
                point:{
                    radius: 0
                }
            },
            stacked: false,
            plugins: {
                legend: {
                    display: true,
                    //onClick: function(event, legendItem) {}
                },
                title: {
                    display: true,
                    //text: 'График ПБР'
                },
                tooltip: {
                    /*filter: function (tooltipItem) {
                        //return tooltipItem.datasetIndex !== 4;
                        return true;
                    },*/
                    //mode: 'interpolate',
                    intersect: false
                },
                zoom: {
                    pan: {
                        enabled: true,
                        modifierKey: 'ctrl',
                    },
                    zoom: {
                        drag: {
                            enabled: true
                        },
                        mode: 'xy',
                        limits: {
                            x: {
                                max: 'original',
                                min: 'original'
                            }
                        },
                        onZoom: function(chart) {
                            //chart.options.scales.x.time.unit = null;
                            chart.chart.options.scales.x.time.unit = null;
                        }
                    },
                },
                annotation: {
                    annotations: {
                        /*pbr: {
                            type: 'box',
                            xMin: false,
                            xMax: false,
                            backgroundColor: '#8a8a8a11',
                        },*/
                    }
                }
            },
            scales: {
                x: {
                    type: 'time',
                    display: true,
                    position: 'bottom',
                    bounds: 'ticks',
                    grid:{
                        color: '#8a8a8a',
                    },
                    border: {
                        dash: [8,4],
                    },
                    ticks: {
                        /*callback: function(value, index, values) {
                            return index<24 ? '|' : '';
                        },*/
                        align: 'center'
                    },
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            //millisecond: 'HH:mm:ss.SSS',
                            second: 'HH:mm:ss',
                            minute: 'HH:mm:ss',
                            hour: 'HH:mm'
                        },
                        tooltipFormat: 'yyyy-MM-dd HH:mm:ss'
                    },
                    sampleSize: 86400,
                    autoSkip: false,
                    autoSkipPadding: 10,
                    maxRotation: 10,
                    minRotation: 10
                },
                y: {
                    //min: minv-10,
                    //max: maxv+10
                    bounds: 'ticks',
                    afterDataLimits(scale) {
                        scale.max = scale.max*1.05;
                    }
                }
            }   
        }
} 

export function get_config_3min(mid3min) {
    
    return {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                point:{
                    radius: 0
                }
            },
            animation: {
                duration: 0
            },
            stacked: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Трехминутная выработка'
                },
                annotation: {
                    annotations: {
                        current: {
                            type: 'line',
                            yMin: mid3min,
                            yMax: mid3min,
                            borderColor: '#336699',
                            borderWidth: 2,
                        },
                        max: {
                            type: 'line',
                            yMin: mid3min,
                            yMax: mid3min,
                            borderColor: '#bb0000',
                            borderWidth: 2,
                        },
                        min: {
                            type: 'line',
                            yMin: mid3min,
                            yMax: mid3min,
                            borderColor: '#bb0000',
                            borderWidth: 2,
                        }
                    }
                }
            },
            scales: {
                x: {
                    //type: 'timeseries',
                    display: true,
                    offset: false,
                    padding: 0,
                    position: 'bottom',
                    grid:{
                        display: false,
                        color: '#8a8a8a77',
                    },
                    border: {
                        display: false,
                        dash: [8,4],
                    },
                    time: {
                        displayFormats: {
                            millisecond: 'HH:mm',
                            second: 'mm:ss',
                            minute: 'HH:mm',
                            hour: 'HH:mm'
                        },
                        tooltipFormat: 'HH:mm:ss'
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 0,
                        backdropPadding: 0,
                        /*callback: function(value, idx, ticks) {
                            var data = window.grdata.rt;
                            
                            return idx%60 == 0 ? 'X' : ( idx%10==0 ? 'XX' : '')
                        }*/
                    }
                },
                x2: {
                    //type: 'timeseries',
                    display: true,
                    offset: false,
                    position: 'bottom',
                    padding: 0,
                    grid:{
                        display: false,
                    },
                    border: {
                        display: false,
                        dash: [8,4],
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 0,
                        backdropPadding: 0,
                        callback: function(value, idx, ticks) {
                            //var data = window.grdata.rt;
                            return idx%10 === 0 ? '|' : '.';
                        }
                    }
                },
                y: {
                    min: mid3min - 5,
                    max: mid3min + 5
                }
            }
        }
    
} 


