import React from 'react';
import {
   Button
} from 'adminlte-2-react';

const ControlSidebar = (isSidebarOpen) => {
  return (
    <div>
      <aside className={`control-sidebar2 control-sidebar control-sidebar-dark ${isSidebarOpen ? 'control-sidebar-open' : ''}`}>
        {/* Home tab content */}
        <div style={{padding: 10}}>
          <h4 className="control-sidebar-heading">Настройки:</h4>
          <ul className='sidebar-menu'>
            <li><Button className="sidebar-button" to="/protocols" text="Приём данных" icon="fa-edit" /></li>
            <li><Button className="sidebar-button" to="/telemeasurements" text="Телеизмерения" icon="fa-edit" /></li>
            <li><Button className="sidebar-button" to="/telesignals" text="Телесигналы" icon="fa-edit" /></li>
            <li><Button className="sidebar-button" to="/units" text="Блоки" icon="fa-edit" /></li>
          </ul>
        </div>
      </aside>

    </div>
  );
}

export default ControlSidebar;
