import {
  Alert,
  Button,
  Typography,
  Form,
  Input,
  message,
  Spin,
} from "antd";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { setToken } from "../../helpers";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import './SignIn.css';
const { Title } = Typography;


const SignIn = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const handleKeycloakRedirect = () => {
    window.location.href = `${API}/connect/keycloak`;
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);

        message.success(`Добро пожаловать ${data.user.username}!`);

        navigate("/");
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Что-то пошло не так!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='login-container'>
      <img src="logo.svg" alt="logo" className="logo-form" />
      <Title level={2}>ЕАЕ: Платформа</Title>
      {error ? (
        <Alert
          className="login-alert"
          message={error}
          type="error"
          closable
          showIcon
          afterClose={() => setError("")}
        />
      ) : null}
      <Form
        name="login_form"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className='login-form'
      >
        <Form.Item

          name="email"
          rules={[
            { required: true, message: 'Введите вашу электронную почту!' },
            { type: 'email', message: 'Неверный формат электронной почты!' },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Эл. почта" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Введите ваш пароль!" }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Пароль" />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' block>
            Войти
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type='default' onClick={handleKeycloakRedirect} block className='keycloak-login'>
            Войти с Keycloak
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignIn;